import React, { useEffect, useState } from 'react';
import '../style/bonus.scss';
import RestaurantLogo from '../images/restaurant_logo.svg';
import BackBtn from '../components/Bonus/BackBtn';
import BonusQrCodeReader from '../components/Bonus/BonusQrCodeReader';
import SelectMode from '../components/Bonus/SelectMode';
import Bill from './Bonus/Bill';
import GuestCardPunch from './GuestCards/GuestCardPunch';
import ProductPackageBuy from './ProductPackages/ProductPackageBuy';
import ProductPackageCancel from './ProductPackages/ProductPackageCancel';
import { trim } from 'lodash';
import UpgradePayingMember from './Member/UpgradePayingMember';

const ScanQRCode = () => {
  const [pageStep, setPageStep] = React.useState('scanQrcode');
  const [scanData, setScanData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // 會員使用情境 bonus 紅利 guestCard 共享空間 payingMember 升級付費會員
  const [memberMode, setMemberMode] = useState();

  useEffect(() => {
    if (trim(scanData) !== '') {
      if (scanData.startsWith('GC_')) {
        setPageStep('guestCard');
      } else if (scanData.startsWith('PACKAGE_BUY')) {
        setPageStep('productPackageBuy');
      } else if (scanData.startsWith('PACKAGE_CANCEL')) {
        setPageStep('productPackageCancel');
      } else {
        setPageStep('member');
      }
    }
  }, [scanData]);

  useEffect(() => {
    // 使用會員 QRCODE 登記共享空間時
    if (memberMode === 'guestCard' && pageStep !== 'guestCard') {
      setPageStep('guestCard');
    }
  }, [memberMode]);

  const backAction = () => {
    setMemberMode(undefined);
  };

  const handleQRCodeScan = (data) => {
    setScanData(data);
  };

  return (
    <div className="bonus">
      <div className="bonus__logo">
        <img src={RestaurantLogo} />
        <div className="bonus__logo-btn">
          <BackBtn backAction={backAction} />
        </div>
      </div>

      {pageStep === 'scanQrcode' && (
        <BonusQrCodeReader
          verifyQrcodeData={handleQRCodeScan}
          isLoading={isLoading}
        />
      )}

      {pageStep === 'member' && !memberMode && (
        <SelectMode selectMode={(mode) => setMemberMode(mode)} />
      )}

      {pageStep === 'member' && memberMode === 'bonus' && (
        <Bill qrcode={scanData} />
      )}

      {pageStep === 'guestCard' && <GuestCardPunch qrcode={scanData} />}

      {pageStep === 'member' && memberMode === 'payingMember' && (
        <UpgradePayingMember qrcode={scanData} />
      )}

      {pageStep === 'productPackageBuy' && (
        <ProductPackageBuy scanData={scanData} />
      )}

      {pageStep === 'productPackageCancel' && (
        <ProductPackageCancel scanData={scanData} />
      )}
    </div>
  );
};

export default ScanQRCode;
