import instance from './API';
import { trim } from 'lodash';

const memberAPI = {
  getMember: (userId) => {
    return instance.get(`/Member/${userId}`);
  },
  upgradePayingMember: (userId) => {
    return instance.get(`/Member/ActivePayingMember`, {
      params: {
        userId,
      },
    });
  },
  checkPayingMember: (qrcode) => {
    return instance.get(`/Member/VerifyPayingMember`, {
      params: {
        qrcode: trim(qrcode),
      },
    });
  },
};

export default memberAPI;
