import React, { useMemo } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import PersonIcon from '@material-ui/icons/Person';
import { Divider, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import { formatNumberComma } from '../../Util/Util';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import BonusProductBarcode from './BonusProductBarcode';
import CropFreeIcon from '@material-ui/icons/CropFree';

const symbolsArr = ['e', 'E', '+', '-', '.'];

const GuestAmount = (props) => {
  const {
    amount,
    setAmount,
    guestMember,
    isLoading,
    pageStep,
    checkBonus,
    addBonus,
    useBonusResult,
    calculateBonus,
    serviceCharge,
    isUseServiceCharge,
    switchServiceCharge,
    reScanQrcode,
  } = props;

  const totalAmount = useMemo(() => {
    if (isUseServiceCharge) {
      return parseInt(amount, 10) + parseInt(serviceCharge);
    } else {
      return amount;
    }
  }, [isUseServiceCharge, serviceCharge, amount]);

  const handleChangeAmount = (e) => {
    const isNumberReg = new RegExp('^[0-9]*$', 'i');
    const value = e.target.value;

    if (isNumberReg.test(value)) {
      setAmount(value);
    }
  };

  const handleGuestAmount = () => {
    if (pageStep === 'checkBonus') {
      checkBonus();
    } else if (pageStep === 'addBonus') {
      addBonus();
    }
  };

  const handleReScanQrcode = () => {
    reScanQrcode();
  };

  return (
    <div className="bonus__guest-amount">
      <Grid
        container
        direction="row"
        justifycontent="space-evenly"
        alignItems="stretch">
        <Grid item xs={4}>
          <div className="bonus__guest-title">
            <PersonIcon /> 會員
          </div>
        </Grid>

        <Grid item xs={8}>
          <div className="bonus__guest-title">{guestMember.contact}</div>
        </Grid>

        {guestMember.companyList && guestMember.companyList.length > 0 && (
          <>
            <Grid item xs={4}>
              <div className="bonus__guest-title bonus__guest-title--warning">
                特約
              </div>
            </Grid>

            <Grid item xs={8}>
              {guestMember.companyList.map((memberCompany) => (
                <div
                  key={memberCompany.id}
                  className="bonus__guest-title bonus__guest-title--warning">
                  {memberCompany.name}
                </div>
              ))}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {guestMember.productInfoList &&
            guestMember.productInfoList.length > 0 &&
            guestMember.productInfoList.map((product) => {
              return (
                <BonusProductBarcode key={product.id} bonusProduct={product} />
              );
            })}

          <Button
            variant="contained"
            color="default"
            size="large"
            fullWidth
            startIcon={<CropFreeIcon />}
            onClick={handleReScanQrcode}
            disabled={isLoading}>
            掃描抵用券
          </Button>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" size="medium" fullWidth>
            <InputLabel>本次消費金額</InputLabel>
            <OutlinedInput
              autoFocus
              value={amount}
              onChange={handleChangeAmount}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              labelWidth={60}
              type="number"
              disabled={isLoading}
              onKeyDown={(e) =>
                symbolsArr.includes(e.key) && e.preventDefault()
              }
            />
          </FormControl>

          {pageStep === 'addBonus' && (
            <>
              <div className="bonus__service-charge">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isUseServiceCharge}
                      size="medium"
                      onChange={() => {
                        switchServiceCharge();
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <span
                      className={`bonus__service-charge__title ${
                        isUseServiceCharge
                          ? ''
                          : 'bonus__service-charge--delete '
                      }
                      `}>
                      服務費 $ {formatNumberComma(serviceCharge)}
                    </span>
                  }
                />
              </div>
              <Divider />
              <div className="bonus__total-amount">
                發票金額 $ {formatNumberComma(totalAmount)}
              </div>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color={pageStep === 'checkBonus' ? 'primary' : 'secondary'}
            size="large"
            fullWidth
            startIcon={
              pageStep === 'checkBonus' ? <RestaurantIcon /> : <AddIcon />
            }
            onClick={handleGuestAmount}
            disabled={isLoading}>
            {pageStep === 'checkBonus' ? '確認' : '增加紅利'}
          </Button>
        </Grid>

        {useBonusResult && (
          <Grid item xs={12}>
            <Alert severity="success">
              已使用 {useBonusResult.use} 紅利點數成功!!
            </Alert>
          </Grid>
        )}

        {calculateBonus && calculateBonus.bonusCalculate <= 0 && (
          <Grid Grid item xs={12}>
            <Alert severity="error">無紅利點數可使用!!</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default GuestAmount;
