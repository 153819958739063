import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { map, trim } from 'lodash';
import { GuestCardStatus, GuestCardStatusText } from '../../data/common';
import moment from 'moment';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { alertError, formatNumberComma, seconds2time } from '../../Util/Util';
import guestCardAPI from '../../middleware/guestCardAPI';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import Swal from 'sweetalert2';
import bonusAPI from '../../middleware/bonusAPI';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  guestCardCode: {
    fontSize: '1.8rem',
    fontWeight: '600',
    margin: theme.spacing(3, 'auto'),
  },
  guestCardStatus: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#eee',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  statusText: {
    fontSize: '1.8rem',
    fontWeight: '600',
  },
  useTime: {
    fontSize: '1rem',
    fontWeight: '400',
    letterSpacing: theme.spacing(0.3),
  },
  useTimeDesc: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  useTimeSec: {
    fontSize: '1.8rem',
    fontWeight: '600',
  },
  desc: {
    fontSize: '0.8rem',
    fontWeight: '600',
    color: '#b98f8f',
    margin: theme.spacing(1, 'auto'),
  },
  buttons: {
    margin: theme.spacing(5, 'auto', 1),
  },
  useResult: {
    backgroundColor: '#ff2d2d',
    color: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  useResultTitle: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  useResultTime: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  billResult: {
    backgroundColor: '#266742',
    color: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  billResultRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  billResultRowTitle: {
    flexBasis: '46px',
  },
  billResultTitle: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  billResultTime: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  billResultPrice: {
    fontSize: '2rem',
  },
  info: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    margin: theme.spacing(1, 'auto'),
    fontSize: '1rem',
  },
  priceTypes: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    margin: theme.spacing(2, 'auto'),
    gap: theme.spacing(1),
  },
  priceType: {
    backgroundColor: '#ffe4e4',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(0.5),
  },
  typeName: {
    fontSize: '1.2rem',
    fontWeight: '600',
    margin: theme.spacing(1, 'auto'),
    textAlign: 'left',
  },
  typeRow: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1, 'auto'),
    gap: theme.spacing(1),
  },
  typePrice: {
    fontSize: '1.5rem',
    fontWeight: '600',
  },
}));

const GuestCardPunch = (props) => {
  const { qrcode } = props;
  const classes = useStyles();
  const [guestCardCode, setGuestCardCode] = useState(); // 共享空間的卡號或使用者ID
  const [guestCard, setGuestCard] = useState();
  const [guestCardState, setGuestCardState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pageStep, setPageStep] = useState('init');
  const [punchResult, setPunchResult] = useState();
  const [selectPriceType, setSelectPriceType] = useState();

  useEffect(() => {
    if (!trim(qrcode).startsWith('GC_')) {
      bonusAPI.verifyQrcode(qrcode).then(
        (rs) => {
          setGuestCardCode(rs.userId);
        },
        (error) => {
          alertError(error);
        },
      );
    } else {
      setGuestCardCode(qrcode);
    }
  }, [qrcode]);

  useEffect(() => {
    if (guestCardCode) {
      setIsLoading(true);

      guestCardAPI
        .getGuestCardInfo(guestCardCode)
        .then(
          (rs) => {
            if (trim(rs.code) === '' && trim(rs.userInfo) === '') {
              Swal.fire({
                title: '找不到資料',
                text: '請重新掃描',
                icon: 'warning',
              });
            } else {
              setGuestCard({
                ...rs,
                cardNumber: trim(rs.code) !== '' ? rs.code : rs.userInfo.id,
              });
              setPageStep('info');
            }
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [guestCardCode]);

  useEffect(() => {
    if (
      guestCard &&
      guestCard.cardHistory &&
      trim(guestCard.cardHistory.signInTime) !== '' &&
      trim(guestCard.cardHistory.signOutTime) === ''
    ) {
      const nowTime = moment();
      const signInTime = moment(guestCard.cardHistory.signInTime);

      setGuestCardState({
        status: GuestCardStatus.USE,
        signInTime: signInTime.format('YYYY-MM-DD HH:mm:ss'),
        useTimeSec: nowTime.diff(signInTime, 'seconds'),
      });
    } else {
      setGuestCardState({
        status: GuestCardStatus.UN_USE,
      });
    }
  }, [guestCard]);

  useEffect(() => {
    if (selectPriceType) {
      checkHistoryType(punchResult.historyId, selectPriceType);
    }
  }, [selectPriceType]);

  const gusetCardStyle =
    guestCardState && guestCardState.status === GuestCardStatus.USE
      ? {
          backgroundColor: '#ff2d2d',
          color: '#fff',
        }
      : undefined;

  const handleUse = () => {
    setIsLoading(true);

    guestCardAPI
      .punch(guestCard.cardNumber)
      .then(
        (rs) => {
          setPunchResult(rs);
          setPageStep('useResult');
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleBill = () => {
    setIsLoading(true);

    guestCardAPI
      .punch(guestCard.cardNumber)
      .then(
        (rs) => {
          setPunchResult(rs);
          setPageStep('billResult');
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleCheckType = (typeId) => {
    setSelectPriceType(typeId);
  };

  const checkHistoryType = () => {
    setIsLoading(true);

    guestCardAPI
      .checkHistoryType(punchResult.historyId, selectPriceType)
      .then(
        (rs) => {},
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      {pageStep === 'init' && (
        <div>
          <CircularProgress />
          <div>讀取中</div>
        </div>
      )}

      {pageStep === 'info' && (
        <>
          <div className={classes.guestCardCode}>
            {guestCard.code !== null && `卡號 ${guestCard.code}`}
            {guestCard.userInfo !== null &&
              `姓名 ${guestCard.userInfo.realName}`}
          </div>

          <div className={classes.guestCardStatus} style={gusetCardStyle}>
            <div className={classes.statusText}>
              {guestCardState && GuestCardStatusText[guestCardState.status]}
            </div>

            {guestCardState && guestCardState.status === GuestCardStatus.USE && (
              <div className={classes.useTime}>
                <div className={classes.useTimeSec}>
                  <div className={classes.useTimeDesc}>使用時間</div>
                  {seconds2time(guestCardState.useTimeSec, true)}
                </div>
              </div>
            )}
          </div>

          {guestCardState && guestCardState.status === GuestCardStatus.USE && (
            <>
              <div className={classes.info}>
                進場時間 {guestCardState.signInTime}
              </div>

              <div className={classes.desc}>計算時間，非即時更新</div>
            </>
          )}

          <div className={classes.buttons}>
            {guestCardState && guestCardState.status === GuestCardStatus.USE && (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                startIcon={<ReceiptRoundedIcon />}
                disabled={isLoading}
                onClick={handleBill}>
                結算
              </Button>
            )}

            {guestCardState &&
              guestCardState.status === GuestCardStatus.UN_USE && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<PlayCircleFilledWhiteRoundedIcon />}
                  onClick={handleUse}
                  disabled={isLoading}>
                  使用
                </Button>
              )}
          </div>

          {guestCardState &&
            guestCardState.status === GuestCardStatus.UN_USE &&
            guestCard.cardHistory && (
              <Accordion style={{ marginTop: '24px' }}>
                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                  最新結算記錄
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className={classes.billResult}
                    style={{
                      backgroundColor: '#eee',
                      color: '#616060',
                      width: '100%',
                    }}>
                    <div className={classes.billResultRow}>
                      <div className={classes.billResultRowTitle}>消費金額</div>
                      <div className={classes.billResultPrice}>
                        {formatNumberComma(
                          guestCard.cardHistory.expenseAmount || 0,
                        )}
                      </div>
                    </div>

                    <div
                      className={classes.billResultRow}
                      style={{ borderTop: '1px solid #fff' }}>
                      <div className={classes.billResultRowTitle}>消費時間</div>
                      <div className={classes.useTimeSec}>
                        {seconds2time(
                          moment(guestCard.cardHistory.signOutTime).diff(
                            guestCard.cardHistory.signInTime,
                            'seconds',
                          ),
                          true,
                        )}
                      </div>
                    </div>

                    <div className={classes.billResultTime}>
                      入場{' '}
                      {moment(guestCard.cardHistory.signInTime).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )}
                    </div>
                    <div className={classes.billResultTime}>
                      出場{' '}
                      {moment(guestCard.cardHistory.signOutTime).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
        </>
      )}

      {pageStep === 'useResult' && (
        <div className={classes.useResult}>
          <div className={classes.useResultTitle}>已啟用</div>
          <div className={classes.useResultTime}>
            {moment(punchResult.signInTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      )}

      {pageStep === 'billResult' && (
        <div>
          <div className={classes.guestCardCode}>
            {trim(guestCard.code) !== '' && <>卡號 {guestCard.code}</>}

            {trim(guestCard.userInfo) !== '' && (
              <>姓名 {guestCard.userInfo.realName}</>
            )}
          </div>

          <div>消費時間</div>

          <div className={classes.useTimeSec} style={{ marginBottom: '8px' }}>
            {seconds2time(
              moment(punchResult.signOutTime).diff(
                punchResult.signInTime,
                'seconds',
              ),
              true,
            )}
          </div>

          <div className={classes.billResultTime}>
            入場 {moment(punchResult.signInTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>

          <div className={classes.billResultTime}>
            出場 {moment(punchResult.signOutTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>

          <div className={classes.priceTypes}>
            {map(punchResult.priceTypeList, (priceType) => {
              return (
                <div key={priceType.typeId} className={classes.priceType}>
                  <div className={classes.typeName}>{priceType.typeName}</div>
                  <div className={classes.typeRow}>
                    <div className={classes.typePrice}>
                      {formatNumberComma(priceType.price) + ' 元'}
                    </div>

                    <Button
                      variant="contained"
                      color={
                        selectPriceType !== priceType.typeId
                          ? 'primary'
                          : 'default'
                      }
                      size="small"
                      startIcon={
                        selectPriceType === priceType.typeId ? (
                          <CheckBoxRoundedIcon />
                        ) : (
                          <CheckBoxOutlineBlankRoundedIcon />
                        )
                      }
                      disabled={
                        isLoading || selectPriceType === priceType.typeId
                      }
                      onClick={() => {
                        handleCheckType(priceType.typeId);
                        Swal.fire({
                          toast: true,
                          position: 'bottom-end',
                          showConfirmButton: false,
                          timer: 3000,
                          title: '完成',
                          icon: 'success',
                        });
                      }}>
                      {selectPriceType === priceType.typeId
                        ? '目前選擇'
                        : '請選擇'}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestCardPunch;
