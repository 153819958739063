import { Button } from '@material-ui/core';
import React from 'react';

const SelectMode = (props) => {
  const { selectMode } = props;

  const handleSelectBonusMode = () => {
    selectMode('bonus');
  };

  const handleSelectGuestCardMode = () => {
    selectMode('guestCard');
  };

  const handleSelectPayingMemberMode = () => {
    selectMode('payingMember');
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '36px',
        marginTop: '60px',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignContent: 'center',
      }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSelectBonusMode}
        style={{
          height: '110px',
          fontWeight: '600',
          fontSize: '1.7rem',
        }}>
        紅利點數或抵用卷
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSelectGuestCardMode}
        style={{
          height: '110px',
          fontWeight: '600',
          fontSize: '1.7rem',
        }}>
        共享空間
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleSelectPayingMemberMode}
        style={{
          height: '110px',
          fontWeight: '600',
          fontSize: '1.7rem',
        }}>
        付費會員
      </Button>
    </div>
  );
};

export default SelectMode;
