import React, { useEffect, useState } from 'react';
import memberAPI from '../../middleware/memberAPI';
import { alertError } from '../../Util/Util';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { orderBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  upgrade: {
    fontSize: '1.8rem',
    fontWeight: '600',
    margin: theme.spacing(3, 'auto'),
    maxWidth: '360px',
  },
  memberInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1rem',
    fontWeight: '600',
    padding: theme.spacing(1),
  },
  payingHistoryList: {
    fontSize: '0.9rem',
    fontWeight: '600',
    listStyle: 'none',
    padding: '0',
  },
  payingHistory: {
    margin: theme.spacing(1),
  },
  card: {
    marginTop: theme.spacing(2),
  },
  success: {
    fontSize: '2rem',
    fontWeight: '600',
    textAlign: 'center',
  },
}));

const UpgradePayingMember = (props) => {
  const { qrcode } = props;
  const classes = useStyles();
  const [pageStep, setPageStep] = useState('init');
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [userPayingList, setUserPayingList] = useState();
  const [isPayingMember, setIsPayingMember] = useState(false);
  const [lastExpirationDate, setLastExpirationDate] = useState();

  useEffect(() => {
    if (qrcode) {
      setIsLoading(true);

      memberAPI
        .checkPayingMember(qrcode)
        .then(
          (response) => {
            if (response.userInfo) {
              setUserInfo(response.userInfo);
              setUserPayingList(response.payingHistoryList);
              setIsPayingMember(response.isPayingMember === true);
              setPageStep('upgrade');

              const sortedPayingList = orderBy(
                response.payingHistoryList,
                ['endAt'],
                ['desc'],
              );

              if (sortedPayingList.length > 0) {
                setLastExpirationDate(
                  sortedPayingList[0].endAt.substring(0, 10),
                );
              }
            }
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [qrcode]);

  const upgradePayingMember = () => {
    setIsLoading(true);

    memberAPI
      .upgradePayingMember(userInfo.id)
      .then(
        (response) => {
          setPageStep('success');
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      {pageStep === 'init' && (
        <div>
          <CircularProgress />
          <div>讀取中</div>
        </div>
      )}

      {pageStep === 'upgrade' && (
        <div className={classes.upgrade}>
          {userInfo && (
            <div>
              <div>會員資訊</div>
              <div className={classes.memberInfo}>
                <div className={classes.memberInfoTitle}>姓名</div>
                <div>{userInfo.realName}</div>
              </div>
              <div className={classes.memberInfo}>
                <div className={classes.memberInfoTitle}>電話</div>
                <div>{userInfo.cellPhone}</div>
              </div>
              <div className={classes.memberInfo}>
                <div className={classes.memberInfoTitle}>電子郵件</div>
                <div>{userInfo.email}</div>
              </div>
              <div className={classes.memberInfo}>
                <div className={classes.memberInfoTitle}>會員狀態</div>
                <div>{isPayingMember ? '付費會員' : '非付費會員'}</div>
              </div>
              {isPayingMember && (
                <div className={classes.memberInfo}>
                  <div className={classes.memberInfoTitle}>到期日</div>
                  <div>{lastExpirationDate}</div>
                </div>
              )}

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={upgradePayingMember}
                  disabled={isLoading}>
                  {isPayingMember ? '續約付費會員' : '升級付費會員'}
                </Button>
              </div>
            </div>
          )}
          {userPayingList && userPayingList.length > 0 && (
            <div className={classes.card}>
              <div>付費紀錄</div>
              <ul className={classes.payingHistoryList}>
                {userPayingList.map((item, index) => (
                  <li key={index} className={classes.payingHistory}>
                    {moment(item.startAt).format('YYYY-MM-DD')} 到{' '}
                    {moment(item.endAt).format('YYYY-MM-DD')}
                    <div style={{ color: '#999' }}>
                      付款時間{' '}
                      {moment(item.payingTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      {pageStep === 'success' && (
        <div>
          <div className={classes.success}>升級成功</div>
        </div>
      )}
    </div>
  );
};

export default UpgradePayingMember;
